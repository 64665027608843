$gutter: 0.5rem; //for primeflex grid system

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "../node_modules/@fullcalendar/core/main.min.css";
@import "../node_modules/@fullcalendar/daygrid/main.min.css";
@import "../node_modules/@fullcalendar/timegrid/main.min.css";
@import "assets/demo/flags/flags.css";
@import "assets/bootstrap-grid.css";
@import "assets/bootstrap-utilities.css";
//@import "~bootstrap/scss/bootstrap";

.hidden {
  visibility: hidden;
}
:host ::ng-deep .drag-column {
  padding-right: 0.5em;
}

.drop-column {
  border: 1px solid transparent;
  transition: border-color 0.2s;

  &.p-draggable-enter {
    border-color: var(--primary-color);
  }
}
.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0;
  }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    //height: calc(1.5em + .75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
  }
  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.p-datatable-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-placeholder {
  text-align: center;
  img {
    width: 93px;
    border: 1px solid;
    padding: 2px;
  }
  button {
    display: inline-block;
  }
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  .breadcrumb-item {
    display: flex;
  }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
  }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "|";
  }
}
.p-tabview {
  .p-tabview-nav {
    li {
      .p-tabview-nav-link:not(.p-disabled):focus {
        box-shadow: none;
      }
    }
  }
}
.layout-breadcrumb {
  .breadcrumb {
    margin-bottom: 0;
  }
}
.form-control {
  width: 100% !important;
  height: 39px;
  .p-dropdown {
    width: 100%;
  }
}
.p-grid {
  .p-md-4 {
    padding: 0 !important;
  }
}
form {
  .row {
    margin-bottom: 16px;
  }
  .label {
    font-weight: 500;
    margin-bottom: 7px;
    display: block;
    font-size: 16px;
  }
  .form-group {
    display: block;
    margin-bottom: 16px;
  }
}
.text-bold {
  font-weight: 600;
}
.img-circle {
  border-radius: 50%;
}
.p-button.chip {
  padding: 2px 3px;
}
.row._mb-2 {
  margin-bottom: 10px;
}

.round-image {
  border-radius: 50%;
}

.has-error {
  color: #f82929;
  font-size: 11px;
  margin-top: 2px;
}
.form-element-error {
  border: 0.15rem solid red;
}
